import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactInfo from "../components/contact/contact-info"

export default function ContactPage() {

  return (
    <Layout>
      <SEO title="Contact"/>
      <div className = "font-montserrat text-4xl text-red-dark">Contact Us</div>
      <br/>
      <div className="flex flex-row">
        <ContactInfo />
      </div>
    </Layout>
  );
}

