import React from "react"

export default function ContactInfo() {
    return(
        <div className="font-lora">
            <div className="text-xl font-bold">
              Contact Information
            </div>
            <div className="pt-4">
              <div>
                Str. Osiceanu Nr. 9
              </div>
              <div>
                117630 Rucăr, Argeș, România
              </div>
              <div>
                Tel/Fax: <a href="tel:+4-0248542230" className="hover:text-red-dark">+40248542230</a>
              </div>
              <div>
                Mobile: <a href="tel:+4-0744370666" className="hover:text-red-dark">+40744370666</a>
              </div>
              <div>
                {"Email: "}
                <a href="mailto:anfmr.romania@gmail.com" className="hover:text-red-dark">anfmr.romania@gmail.com</a>
              </div>
              <div>
                {"Facebook: "}
                <a href="https://www.facebook.com/ANFMR-Romania-108228857698046/" className="hover:text-red-dark" target="_blank" rel="noopener noreferrer">ANFMR Romania</a>
              </div>
              <div>
                {"LinkedIn: "}
                <a href="https://www.linkedin.com/company/anfmr/" className="hover:text-red-dark" target="_blank" rel="noopener noreferrer">ANFMR</a>
              </div>
            </div>
        </div>
    )
}